import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import get from "lodash.get";

import { REQUEST_STATUS, useDebounce, useRequest } from "hooks";
import { IOrder } from "pages/hr/driver/details/context/DetailsContext.types";
import Loader from "pages/loader/container/Loader";
import { SearchParams } from "services/urlParams/urlParams";

import { TableFooter, TableHead } from "./components/index";
import {
  StyledTable,
  TableBodyStyled,
  TableCellCheckbox,
  TableContainerStyled,
  TableWrapperStyled,
} from "./Table.style";
import { ITableProps } from "./Table.types";
import { debounce } from "lodash";
import useOrderUpdateDebounce from "./utils/useRowsDebounce";
import mergeOrderArrays from "./utils/mergeArrays";
// import { dragEndHandler } from "./utils/dragEndHandler/DragEndHandler";
// import { dragLeaveHandler } from "./utils/dragLeaveHandler/DragLeaveHandler";
// import { dragOverHandler } from "./utils/dragOverHandler/DragOverHandler";

// Компонент который используется активации drag-n-drop при клике внутри компонента
const DragHandle = SortableHandle(({ style }: any) => (
  <span style={{ ...style, ...{ cursor: "move" } }}>{"::::"}</span>
));

// Универсальный компонент для превращения TableBody в sortable контейнер
const TableBodySortable: any = SortableContainer(
  ({ children, displayRowCheckbox }: any) => (
    <TableBodyStyled displayRowCheckbox={displayRowCheckbox}>
      {children}
    </TableBodyStyled>
  )
);
// Компонент строки таблицы с оберткой в sortable элемент

// Строка необходима для того чтобы наш кастомный боди воспринимался как TableBody и в этом случае ошибки не будет
TableBodySortable.muiName = "TableBody";

function Table<TDataType extends { _id: string; date?: string }>({
  columns = [],
  useIdForKey = false,
  onRowClick,
  dataUrl,
  optional,
  selection = false,
  draggable = false,
  draggableChange = () => {},
  handleCheckboxChange = () => {},
  render = false,
  filter = "",
  getData,
  updatedOrder,
  newOrder,
  urlParams = "",
  hasPagination = true,
  dataKey = "data.data",
  onDataLoad = undefined,
  additionalRow,
  filterData,
  defaultRowPerPage = 100,
}: ITableProps<TDataType>) {
  const [client, res, status, error] = useRequest();
  const [setParams, getParams] = SearchParams();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowPerPage);
  const [selected, setSelected] = useState<readonly string[]>([]);
  // const [currentItem, setCurrentItem] = useState<any>();
  const [fetchData, setFetchData] = useState<TDataType[]>([]);
  const { t } = useTranslation();
  // function useQuery() {
  //   const { search } = useLocation();
  //   return useMemo(() => new URLSearchParams(search), [search]);
  // }
  // let query = useMemo(() => new URLSearchParams(search), [search]);
  const onSortEnd = ({ oldIndex, newIndex, data }: any) => {
    const newOrderData = arrayMove(fetchData, oldIndex, newIndex);
    draggableChange({
      oldIndex,
      newIndex,
      data,
      newOrderData,
    });
    setFetchData(newOrderData);
  };

  const { debouncedRows, setDebouncedRows } = useOrderUpdateDebounce(
    updatedOrder,
    newOrder,
    1000,
    setFetchData
  );

  // console.log("---updatedOrder: ",  updatedOrder);
  // console.log("---debouncedRows: ", debouncedRows);

  useEffect(() => {
    console.log("---debouncedRows: ", debouncedRows);
    if (updatedOrder && debouncedRows.length) {
      console.count("---setFetchData");
      setFetchData((prev) => {
        const newFetchData = mergeOrderArrays(prev, debouncedRows);
        return newFetchData;
      });
      setDebouncedRows([]);
    }
  }, [debouncedRows]);

  useEffect(() => {
    let newQueryData = {
      limit: getParams().limit
        ? parseInt(getParams().limit)
        : defaultRowPerPage,
      page: getParams().page ? parseInt(getParams().page) : 1,
    };
    if (hasPagination) {
      setPage(newQueryData.page);
      setRowsPerPage(newQueryData.limit);
    }
    fetchDataFunction(newQueryData);
  }, [page, rowsPerPage, render, filter, urlParams]);

  const fetchDataFunction = async ({ limit, page }: any) => {
    setParams({
      ...getParams(),
      ...filter,
      limit,
      page,
    });
    // let request = {
    //   ...getParams(),
    //   limit: parseInt(getParams().limit) === -1 ? 0 : getParams().limit,
    // };
    if (!!dataUrl) {
      const paginationParams = `${
        limit !== -1 && `limit=${limit ? limit : getParams()?.limit}`
      }&page=${page ? page : getParams().page}`;

      let queryParams = `?${hasPagination ? paginationParams : ""}${
        optional?.driverId ? `&driverId=${optional?.driverId}` : ""
      }${optional?.userId ? `&userId=${optional?.userId}` : ""}${
        filter?.cityId ? `&cityId=${filter?.cityId}` : ""
      }${filter?.isOnline ? `&isOnline=${filter?.isOnline}` : ""}${
        filter?.countryId ? `&countryId=${filter?.countryId}` : ""
      }${
        filter?.carPhotoState ? `&carPhotoState=${filter?.carPhotoState}` : ""
      }${
        filter?.licensePhotoState
          ? `&licensePhotoState=${filter?.licensePhotoState}`
          : ""
      }${filter?.publisherId ? `&publisherId=${filter?.publisherId}` : ""}${
        filter?.genreId ? `&genreId=${filter?.genreId}` : ""
      }${filter?.state ? `&state=${filter?.state}` : ""}${
        filter?.isPremium ? `&isPremium=${filter?.isPremium}` : ""
      }${filter?.paymentType ? `&paymentType=${filter?.paymentType}` : ""}${
        filter?.agentId ? `&agentId=${filter?.agentId}` : ""
      }
      ${filter?.driverId ? `&driverId=${filter?.driverId}` : ""}${
        filter?.status ? `&status=${filter?.status}` : ""
      }${filter?.userId ? `&userId=${filter?.userId}` : ""}${
        filter?.paymentMethod ? `&paymentMethod=${filter?.paymentMethod}` : ""
      }${filter?.source ? `&source=${filter?.source}` : ""
      }${filter?.tariffId ? `&tariffId=${filter?.tariffId}` : ""}${
        filter?.brandId ? `&brandId=${filter?.brandId}` : ""
      }${
        filter?.dateFrom
          ? `&dateFrom=${new Date(filter?.dateFrom).toISOString()}`
          : ""
      }${
        filter?.dateTo
          ? `&dateTo=${new Date(filter?.dateTo).toISOString()}`
          : ""
      }${filter?.type ? `&type=${filter?.type}` : ""}${
        filter?.isCompetitor || filter?.isCompetitor === false
          ? `&isCompetitor=${Boolean(filter?.isCompetitor)}`
          : ""
      }`;
      if (urlParams) {
        queryParams = queryParams + "&" + urlParams;
        // queryParams = `${queryParams}&${urlParams}`;
      }
      const resultURL =
        dataUrl +
        queryParams?.replace(/\s/g, "") +
        `${
          filter?.search && typeof filter?.search === "string"
            ? `&search=${filter?.search}`
            : ""
        }`;
      await client.get(resultURL);
      // await client.get(`${dataUrl}${queryParams?.trim()}`);
    }
  };

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      const data: TDataType[] = get(res, dataKey, []);
      setFetchData(data);
      onDataLoad?.(data);
      if (getData) getData(data);
      if (selection) {
        let defaultSelected: string[] = [];
        data?.forEach((item: any) => {
          if (item.isChecked) {
            defaultSelected.push(item._id);
          }
        });
        setSelected(defaultSelected);
      }
    }
  }, [status]);

  useEffect(() => {
    if (!hasPagination) {
      setFetchData(res?.data?.data);
    }
    if (status === REQUEST_STATUS.success) {
      if (getData) getData(res?.data?.data);
    }
  }, [res?.data?.data?.length]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setParams({
      ...getParams(),
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    if (typeof event?.target?.value === "object") {
      setParams({
        ...getParams(),
        page: 1,
        limit: 0,
      });
      setRowsPerPage(0);
    } else {
      setParams({
        ...getParams(),
        page: 1,
        limit: parseInt(event.target.value),
      });
      setRowsPerPage(parseInt(event?.target?.value));
    }
    setPage(1);
  };

  const handleClick = (event: any, _id: string) => {
    let checkboxChangeQuery = {
      checked: event.target.checked,
      _id,
    };
    handleCheckboxChange(checkboxChangeQuery);
    const selectedIndex = selected.indexOf(_id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (_id: string) => selected.indexOf(_id) !== -1;

  // function dragStartHandler(e: any, item: any) {
  //   setCurrentItem(item);
  // }

  // const dropHandler = async (e: any, item: any) => {
  //   e.preventDefault();
  //   let filterFetchData = fetchData?.filter(
  //     (fetchDataItem: any) => fetchDataItem._id !== currentItem._id
  //   );
  //   setFetchData(filterFetchData);
  //   const currentIndex = fetchData?.indexOf(item);
  //   filterFetchData.splice(currentIndex, 0, currentItem);
  //   let fetchDataIdes: string[] = [];
  //   fetchDataIdes = filterFetchData.map((item: any) => item._id);
  //   // await draggableChange({ _id: currentItem._id, position: currentIndex + 1 });
  // };

  // if (status === REQUEST_STATUS.loading) return <Loader />;

  if (status === REQUEST_STATUS.failed)
    return <div>{error?.response?.data?.message}</div>;

  const height = document.getElementById("table-top")?.clientHeight;

  const Row = SortableElement(({ data, item, ...other }: any) => {
    return (
      <TableRow {...other}>
        {/* {other.children[0]} */}
        <TableCell>
          <DragHandle />
        </TableCell>
        {columns.map((column, index) =>
          column.renderItem ? (
            <TableCell
              className={column.cellClassName ? column.cellClassName : ""}
              key={column.title || index}
            >
              {column.renderItem(item, column, index)}
            </TableCell>
          ) : (
            <TableCell
              className={column.cellClassName ? column.cellClassName : ""}
              key={column.title || index}
            >
              {get(item, `${column.dataKey as string}`)}
            </TableCell>
          )
        )}
      </TableRow>
    );
  });

  const renderData = additionalRow ? [...fetchData, additionalRow] : fetchData;

  return (
    <TableWrapperStyled>
      <TableContainerStyled height={height}>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead columns={columns} selection={selection} />
          {!draggable ? (
            <TableBodyStyled>
              {(!!filterData ? filterData(renderData) : renderData)?.map?.(
                (item, index: number) => {
                  const isItemSelected = isSelected(item._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // @ts-ignore
                  const key_id = useIdForKey ? item?.id || item._id : index;
                  return (
                    <TableRow
                      hover
                      role=" "
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      // @ts-ignore
                      key={key_id}
                      onClick={(event: any) => {
                        onRowClick && onRowClick(item);
                      }}
                      selected={isItemSelected}
                      className={`item ${
                        columns?.length > 1 ? "columns" : ""
                      } ${!!onRowClick ? "row-hover" : ""}`}
                    >
                      {selection && (
                        <TableCellCheckbox onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, item._id)}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCellCheckbox>
                      )}
                      <TableCell key={key_id}>
                        {item.date !== "Jami" &&
                          (index +
                          1 +
                          (getParams().page - 1) * getParams().limit
                            ? index +
                              1 +
                              (getParams().page - 1) * getParams().limit
                            : index + 1)}
                      </TableCell>
                      {columns.map((column, index) =>
                        column.renderItem ? (
                          <TableCell
                            className={
                              column.cellClassName ? column.cellClassName : ""
                            }
                            key={column.title || index}
                          >
                            {column.renderItem(item, column, index)}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={
                              column.cellClassName ? column.cellClassName : ""
                            }
                            key={column.title || index}
                          >
                            {get(item, `${column.dataKey as string}`)}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                }
              )}
            </TableBodyStyled>
          ) : (
            <TableBodySortable
              onSortEnd={({ oldIndex, newIndex, ...data }: any) => {
                onSortEnd({ oldIndex, newIndex, data });
              }}
              useDragHandle
              displayRowCheckbox={false}
            >
              {renderData?.map((row, index) => (
                <Row
                  index={index}
                  key={row._id}
                  // @ts-ignore
                  data={row}
                  item={row}
                />
              ))}
            </TableBodySortable>
          )}
          {status === REQUEST_STATUS.success && !renderData?.length && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={columns.length + 1}>
                  <div className="p-4">
                    {t("components.table.data_not_found")}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {status === REQUEST_STATUS.loading && (
            <TableBody className={"loader-body"}>
              <TableRow>
                <TableCell align="center" colSpan={columns.length + 1}>
                  <Loader isTable size={50} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {hasPagination && !draggable && (
            <TableFooter
              draggable={draggable}
              colSpan={columns.length + 1}
              count={get(res, "data.total") || renderData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </StyledTable>
      </TableContainerStyled>
      <div className="normalize-table"></div>
    </TableWrapperStyled>
  );
}

export default Table;
